.ib-card-wrapper {
  height: 100%;
  min-height: 110px;

  .ib-card {
    /deep/.el-card__body {
      width: 100%;
    }
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    background-color: $white;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .ib-card__active {
    background-color: $blue;
  }

  .ib-image-wrapper {
    display: flex;
    justify-content: center;
  }
  .ib-image {
    margin-top: 15px;
    width: 40px;
    display: block;
    align-self: flex-start;
  }
  .ib-card-title {
    text-align: center;
    background-color: $blue;
    margin-top: 10px;
    height: 30px;
    span {
      font-size: 12.641px;
      color: $white;
      line-height: 2.31;
    }
  }
  .ib-card-title__active {
    background-color: $white;
    span {
      color: $blue;
    }
  }
  .ib-card-subtitle {
    margin-top: 8px;
    text-align: center;
    color: $blue;
    font-size: smaller;
    margin-bottom: 5px;
  }
  .ib-card-subtitle__active {
    color: $white;
  }
}
@media (max-width: 768px) {
  .ib-card-wrapper {
    .ib-image {
      margin-top: 10px;
      width: 30px;
    }
  }
}
@media (max-width: 550px) {
  .ib-card-wrapper {
    height: 100%;
    min-height: unset;
    .ib-card-title {
      margin-top: 10px;
      height: 30px;
      span {
        font-size: 8.641px;
        color: $white;
        line-height: 2;
      }
    }
    .ib-card-title__active {
      span {
        font-size: 8.641px;
        color: $blue;
      }
    }
    .ib-image {
      margin-top: 5px;
      width: 20px;
    }
    .ib-card {
      height: 63px;
    }
    .ib-card-title {
      font-size: 8px;
      height: 15px;
      margin-top: 2px;
    }
    .ib-card-subtitle {
      margin-top: 2px;
      font-size: 8px;
    }
  }
}
