#ib-level {
  .ib-level-content {
    margin-top: 20px;

    .arrow-steps {
      margin-bottom: 50px;
      .step {
        font-size: 14px;
        text-align: center;
        color: #777;
        cursor: default;
        margin: 0 3px 0 0;
        padding: 15px 0px 10px 0px;
        width: 15%;
        @include rtl-sass-value(float, left, right);
        position: relative;
        background-color: $white;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 53px;
        span {
          margin-top: 20px;
        }
      }
    }

    .arrow-steps .step:after,
    .arrow-steps .step:before {
      content: '';
      position: absolute;
      top: 0;
      @include rtl-sass-prop(right, left, -17px);
      width: 0;
      height: 0;
      border-top: 26px solid transparent;
      border-bottom: 26px solid transparent;
      @include rtl-sass-prop(border-left, border-right, 18px solid $white);
      z-index: 2;
    }

    .arrow-steps .step:before {
      @include rtl-sass-prop(right, left, auto);
      @include rtl-sass-prop(left, right, 0);
      @include rtl-sass-prop(border-left, border-right, 18px solid $light-gray);
      z-index: 0;
    }

    .arrow-steps .step:first-child:before {
      border: none;
    }

    .arrow-steps .step:first-child {
      @include rtl-sass-prop(border-top-left-radius, border-top-right-radius, 4px);
      @include rtl-sass-prop(border-bottom-left-radius, border-bottom-right-radius, 4px);
    }

    .arrow-steps .step:last-child {
      @include rtl-sass-prop(border-top-right-radius, border-top-left-radius, 4px);
      @include rtl-sass-prop(border-bottom-right-radius, border-bottom-left-radius, 4px);
    }

    .arrow-steps .step span {
      position: relative;
    }

    *.arrow-steps .step.done span:before {
      opacity: 1;
      content: '';
      position: absolute;
      top: -2px;
      @include rtl-sass-prop(left, right, -10px);
      font-size: 11px;
      line-height: 21px;
    }

    .arrow-steps .step.current {
      color: $white;
      background-color: $blue;
    }

    .arrow-steps .step.current a {
      color: $white;
      text-decoration: none;
    }

    .arrow-steps .step.current:after {
      @include rtl-sass-prop(border-left, border-right, 18px solid $blue);
    }

    .ib-level-content--upper {
      margin-top: 8px;
      margin-bottom: 35px;
      display: flex;
      justify-content: center;
      .ib-level-card {
        /deep/.el-card__body {
          width: 100%;
        }
        border-style: solid;
        border-width: 1px;
        border-radius: 6px;
        background-color: $white;
        width: 274px;
        height: 188px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        .ib-image--upper {
          margin-top: 25px;
          margin-bottom: 10px;
          width: 50px;
          display: block;
          align-self: flex-start;
        }
        .ib-level-content-title {
          background-color: $blue;
          height: 44px;
          text-align: center;
          color: $white;
          line-height: 2.5;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 16.614px;
          word-wrap: break-word;
          padding: 0 3px;
          min-height: 40px;
        }
        .ib-level-content-subtitle {
          text-align: center;
          font-size: 13px;
          color: $blue;
        }
      }
      .ib-level-image-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  .ib-level--alert {
    p {
      font-size: 16px;
      color: $blue;
      text-align: center;
      margin: 30px 0;
    }
  }
  @media (max-width: 1024px) {
    .ib-level-content {
      .arrow-steps {
        .step {
          width: 22%;
        }
      }
    }
  }

  @media (max-width: 550px) {
    .ib-level-content {
      .arrow-steps {
        .step {
          width: 22%;
          @include rtl-sass-prop(padding-left, padding-right, 15px);
        }
      }
      .ib-level-content--upper {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        .ib-level-card {
          width: 164px;
          height: 126px;
          .ib-image--upper {
            width: 30px;
            margin-top: 10px;
            margin-bottom: 8px;
          }
          .ib-level-content-title {
            margin-top: 5px;
            height: 34px;
            min-height: unset;
            line-height: 2.1;
          }
        }
      }
    }
  }
}
