.level-card-wrapper {
  .showMore-wrapper {
    display: flex;
    justify-content: center;
    img {
      width: 30px;
      align-self: flex-start;
    }
  }

  .content {
    margin-bottom: 25px;

    .content-centered {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 127px;
      cursor: pointer;
      margin: 0 5px;

      .content-image {
        width: 30px;
        margin-top: 20px;
      }
    }

    .card-content-wrapper {
      margin-top: 10px;
      width: 184px;
      height: 127px;
      padding: 0;
    }

    @media (max-width: 1024px) {
      .content-centered {
        .content-image {
          width: 20px;
        }
      }

      .card-content-wrapper {
        width: 189px;
        margin: 0 5px;
      }
    }

    @media (max-width: 768px) {
      .content-centered {
        margin: 0;
      }

      .card-content-wrapper {
        width: 145px;
        height: 106px;
      }
    }
  }

  @media (max-width: 550px) {
    .showMore-wrapper img {
      width: 21px;
    }

    .content {
      .content-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 77px;
        cursor: pointer;
        margin: 0;
        .content-image {
          margin-top: 20px;
          width: 15px;
        }
      }

      .card-content-wrapper {
        width: 85px;
        height: 65px;
        margin: 0;
        padding: 0 3px;
      }
    }
  }
}
